.button{

    cursor: pointer;
    margin-top: 0.75rem;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    padding: 0.35em 1em;
    background-color: #ff0066;
    color: #fff;
    font-size: .875rem;
    display: flex;
    line-height: 1.5;
    align-items: center;
    outline: none;
    border-radius: 8px;
    font-size: 13px;
    font-weight: bold;
    justify-content: center;
    border: 2px solid #ff0066;
}
.button:disabled{
    background-color: #ff006677;
    cursor: not-allowed;
    border: 2px solid #ff006677;
}