.flexSection {
  width: 100%;
  display: flex;
}
.gridDashboardCard {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.firstGraph {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 30px;
  background-color: #e9eaf9;
}

.firstGraph svg[style='background: rgb(255, 255, 255);'] {
  fill: #e9eaf9 !important;
  background: #e9eaf9 !important;
}

.headerSection {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  width: 100%;
  position: absolute;
  align-items: center;
  display: flex;
  padding: 1em 1.5em;
  background: #e9eaf9 !important;
  z-index: 10;
  color: #7f7f7f;
  box-sizing: border-box;
  justify-content: space-between;
  font-size: 15px;
  top: 0px;
  left: 0px;
}

.headerSection select {
  background: none;
  font-weight: bold;
  font-size: 15px;
  border: none;
  width: 60px;
}

.RecentOrderCover {
  width: 100%;
  /* background: #e9eaf9 !important; */
  margin-top: 30px;
  height: 300px;
  position: relative;
}

.gridHeaderSection {
  height: 63px;
  padding: 1em 1.5em;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 0.5fr 0.7fr 0.5fr 0.5fr;
  place-items: center;
  font-size: 14px;
  background: #e9eaf9 !important;
  color: #7f7f7f;
}

.gridHeaderSection strong:first-child {
  /* align-self: flex-start; */
  justify-self: flex-start;
}

.gridBodySection {
  height: 63px;
  padding: 1em 1.5em;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 0.5fr 0.7fr 0.5fr 0.5fr;
  font-size: 16px !important;
  color: #7f7f7f;
  background-color: #e9eaf966;
}
.gridBodySection small {
  justify-self: center;
}
.gridBodySection small:first-child {
  justify-self: flex-start;
}
.gridBodySection:nth-child(odd) {
  background-color: #fff;
}

.pending {
  width: max-content;
  padding: 0.4em 1em;
  border-radius: 12px;
  font-size: 10px;
  background-color: #ff6251;
  color: #fff;
}

.completed {
  width: max-content;
  padding: 0.4em 1em;
  border-radius: 12px;
  font-size: 10px;
  box-sizing: border-box;
  background-color: #ff0066;
  color: #fff;
}
.smallStrong {
  margin-right: 6px;
  font-size: 14px;
  display: none;
}
.centerSpan {
  justify-self: center;
}








@media (max-width: 1246px) {
  .gridDashboardCard {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 600px) {
  .gridHeaderSection {
    display: none;
  }

  .gridBodySection {
    grid-template-columns: 1fr 0.3fr;
    height: 120px;
  }
  .gridBodySection small {
    justify-self: start;
  }
  .centerSpan {
    justify-self: start;
  }
  .smallStrong {
    display: inline;
  }
  .gridBodySection small:last-child {
    /* font-weight: bold; */
    font-size: 15px;
  }
}
