.flexSection {
  width: 100%;
  display: flex;
}

.noProducts{
  background-color: #E9EAF9;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7f7f7f;
  margin-top: 100px;
}

.subNavCover {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.PageHeaderCover {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}
.secondForm {
  width: 230px;
  margin-right: 20px;
  height: 40px;
  border-radius: 6px;
  box-shadow: 0px 0px 3px #6c757d;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
}
.buttonFormSection {
  border-radius: 5px;
  background-color: #ff0066;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 40px;
}
.sideEachPageHeader {
  display: flex;
  width: max-content;
  height: 100%;
  align-items: center;
}
.eachPageHeader {
  color: #7f7f7f;
}
.searchFormSection {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border: none;
  color: #6c757d;
  padding-left: 12px;
  outline: none;
}

.cardCover {
  width: 100%;
  position: relative;
  padding: 1em;
  box-sizing: border-box;
  padding-bottom: 2em;
  overflow: hidden;
}
.mappedStoresCover {
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
}
.cardListingCover {
  width: 100%;
}
.cardHeader {
  border-color: #dee2e6;
  box-shadow: 0px 0px 5px #6c757d;
  color: #6c757d;
  filter: drop-shadow(0px 0px 2px #6c757d);
  display: grid;
  width: 100%;
  overflow: hidden;
  padding: 15px 10px;
  background: #e9eaf9 !important;
  box-sizing: border-box;
  font-size: 14px;
  align-self: center;
  text-align: center;
  grid-template-columns: 0.4fr 0.3fr 0.3fr 0.3fr 0.3fr 0.4fr 0.3fr 80px;
}
.cardHeader strong {
  place-items: center;
  margin: auto;
}
.showingCover {
  width: 140px;
  height: 34px;
  font-size: 13px;
  margin-top: 10px;
  font-weight: 300;
  color: #7f7f7f;
  display: flex;
  align-items: center;
  border: 1px solid #7f7f7f;
  border-radius: 4px;
  padding-left: 10px;
  box-sizing: border-box;
}
.paginationClass {
  display: flex;
  width: max-content;
  margin-left: auto;
}
.paginationClass li {
  list-style: none;
  color: #521c54;
  margin: 0px 8px;
  width: 25px;
  cursor: pointer;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationClass li[class='selected'] {
  background-color: #ff0066;
  color: #fff;
  padding-right: 1px;
}
.mappedOrders {
  background-color: #fff;
  position: relative;
  height: 60px;
  margin-bottom: 2px;
  color: #6c757d;
  display: grid;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 14px;
  grid-template-columns: 0.4fr 0.3fr 0.3fr 0.3fr 0.3fr 0.4fr 0.3fr 80px;
}
.mappedOrders:nth-child(odd) {
  background-color: #e9eaf998;
}
.mappedOrders div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  border-left: 2px solid #f5f5f5;
  height: 100%;
}

.myOrderItemCover:first-child {
    box-sizing: border-box;
}
.strongSection {
  margin-right: 6px;
  display: none;
}

.trueVisibilityCover {
    width: max-content;
    padding: 0.4em 1.5em;
    background-color: #ff0066;
    border: none;
    outline: none;
    border-radius: 10px;
    color: #fff;
    font-size: 10px;
  }
.viewBTN{
    color: #ff0066;
    text-decoration: underline;
    border: none;
    cursor: pointer;
    outline: none;
    background-color: inherit;
}










@media (max-width: 680px) {
  .cardCover {
    padding: 1em 0px;
  }
  .mappedOrders {

    grid-template-columns: 1fr;
    grid-gap: 8px;
    /* height: 100px; */
    padding-bottom: 50px !important;
    position: relative;
    height: max-content;
    padding: 13px 0px;
    box-sizing: border-box;
  }
  .cardHeader {
    display: none;
  }
  .myOrderItemCover {
    place-items: left !important;
    margin-right: auto;
    padding-left: 1em;
    box-sizing: border-box;
  }
  .myOrderItemCover:nth-child(4) {
      position: absolute;
      top: 15px;
      right: 10px;
      height: max-content;
      border: none;
  }
  .myOrderItemCover:nth-child(4) .strongSection {
      font-size: 10px;
  }
  .myOrderItemCover:last-child {
      position: absolute;
      bottom: 15px;
      right: 10px;
      height: max-content;
      border: none;
  }
  .myOrderItemCover:nth-child(3) {
    position: absolute;
    bottom: 15px;
    left: 10px;
    height: max-content;
    border: none;
  }
  .myOrderItemCover:nth-child(5) {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    right: 0px;
    font-weight: bold;
    height: max-content;
    border: none;
  }
  .strongSection {
    padding-left: 5px;
    box-sizing: border-box;
    display: block;
  }
}
