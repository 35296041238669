.mainSideBarSection {
    min-width: 290px;
    height: max-content;
    border: 2px solid #f5f5f5;
    box-shadow: 0px 0px 13px #f5f5f5;
    /* height: 400px; */
    background-color: #fff;
    transition: 0.2s;
  }
  
  body[data-side-navigation-mode='open'] .mainSideBarSection {
    transform: translateX(0) !important;
  }
  .SidebarMainCover {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .SidebarHeaderCover {
    background-color: #e9eaf9;
    width: 100%;
    height: 219px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .SidebarHeaderRoundCover {
    width: 93px;
    height: 93px;
    background-color: #ff0066;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .SidebarUsername {
    color: #ff0066;
    font-family: Roboto;
    text-transform: capitalize;
    font-size: 16px;
  }
  
  .EachLinkCover {
    display: flex;
    color: #7f7f7f;
    align-items: center;
    margin: 14px 0px;
    padding: 14px 0px;
    cursor: pointer;
    fill: #7f7f7f;
  }
  
  .EachLinkCover h5 {
    color: #7f7f7f;
    font-size: 13px;
    margin: 3px 0px 0px 1em;
  }
  
  .EachLinkCover strong {
    font-size: 13px;
    margin-left: auto;
    margin-top: 8px;
    margin-right: 10px;
  }
  
  .SidebarLinkCover {
    box-sizing: border-box;
    padding: 1em 2em;
    margin-top: 1em;
  }
  .SidebarLinkCover a {
    display: block;
    width: 100%;
  }
  .SidebarLinkCover a[aria-current="page"] * {
    color: #ff0066;
    fill: #ff0066;
  }
  .bodySectionSection {
    /* padding: 0px 2em; */
    width: 100%;
    box-sizing: border-box;
  }
  
  .confirmRemoval{
    color: 000033;
  }
  
  .removed{
    color: #7F7F7F;
    font-size: 14px;
    width: 300px;
  }
  .semiLinkCover{
    width: 100%;
    padding-left: 20px;
    box-sizing: border-box;
    transition: .240s ease-in-out;
    overflow: hidden;
    height: max-content;
  }
  
  @media (max-width: 999px) {
    .mainSideBarSection {
      z-index: 1000;
      /* overflow-y: scroll; */
      width: 230px;
      position: absolute;
      transform: translateX(-330px) !important;
    }
    .SidebarHeaderCover {
      height: 179px;
    }
    .SidebarHeaderRoundCover {
      margin-top: 10px;
    }
  }
  